import React, {Fragment, useContext} from "react";
import {sfAuthProvider} from "./auth";
import {Navigate, useLocation} from "react-router-dom";
import {CONNECT_TYPE_EX, redirectUrlLogin} from "./common";
import {TsAdminContext} from "./context/ts-admin";
import {QrSettingExPage} from "./components/qr-setting/index-ex";
import {QrSettingPage} from "./components/qr-setting";

interface AuthContextType {
    accessToken: any;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({children}: { children: React.ReactNode }) {
    let [accessToken, setAccessToken] = React.useState<any>(
        localStorage.getItem('access-token') ? JSON.stringify(localStorage.getItem('access-token')) : ''
    );
    let signin = (accessToken: string, callback: VoidFunction) => {
        return sfAuthProvider.signin(() => {
            setAccessToken(accessToken);
            callback();
        });
    };

    let signout = (callback: VoidFunction) => {
        return sfAuthProvider.signout(() => {
            callback();
            redirectUrlLogin();
        });
    };
    let value = {accessToken, signin, signout};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuth({children}: { children: JSX.Element }) {
    let auth = useAuth();
    if ((!auth.accessToken || auth.accessToken === "")) {
        redirectUrlLogin();
    }
    return children;
}

export function AdminRole({children}: { children: JSX.Element }) {
    let userInfo = JSON.parse(localStorage.getItem('user-info') || '');
    if (userInfo && !userInfo.isSuperAdmin) {
        window.location.href = '/404'
    }
    return children;
}

export function OrgType({children}: { children: JSX.Element }) {
    const [orgInfo] = useContext(TsAdminContext);
    let currentOrg = orgInfo?.currentOrg;
    return currentOrg?.ConnectType == CONNECT_TYPE_EX ? <QrSettingExPage/> : <QrSettingPage/>;
}